<template>
  <div class="assetsListBox">
    <NavBar title=" " class="noBorder"></NavBar>
    <div class="bannerBox">
      <div class="title">{{ detail.coinName }}</div>
      <div class="bottomBox">
        <div>
          <div>总额</div>
          <div class="number">{{ $toFixed(detail.balance, detail.xiaoShuDian, true) }}</div>
        </div>
        <div>
          <div>可用</div>
          <div class="number">{{ $toFixed(detail.available, detail.xiaoShuDian, true) }}</div>
        </div>
        <div>
          <div>{{ detail.coinName.toUpperCase() === 'FIL' ? '质押' : '冻结' }}</div>
          <div class="number">{{ $toFixed(detail.frozen, detail.xiaoShuDian, true) }}</div>
        </div>
      </div>
    </div>
    <div class="buttonBox">
      <!-- <div @click="goRouter('addCoin')" v-if="detail.isDeposit && detail.walletAccountId !== 2">
        <img src="@/assets/images/personal/myAssets/a1.png" alt="" />
        <div>充值</div>
      </div> -->
      <div @click="goRouter('getCoin')" v-if="detail.isWithdraw && detail.walletAccountId !== 2">
        <img src="@/assets/images/personal/myAssets/a3.png" alt="" />
        <div>提取</div>
      </div>
      <div @click="goRouter('translateCoin')">
        <img src="@/assets/images/personal/myAssets/a2.png" alt="" />
        <div>划转</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      detail: {
        userId: 0,
        walletAccountId: 0,
        coinId: 0,
        coinName: '',
        balance: 0,
        available: 0,
        frozen: 0,
        xiaoShuDian: 0,
        isWithdraw: 0,
        isDeposit: 0,
      },
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    getDetail() {
      this.$http('post', '/v1/user/userAssetsInfo', {
        walletAccountId: this.$route.query.classId,
        coinId: this.$route.query.coinId,
      }).then(res => {
        this.detail = res.data;
      });
    },
    goRouter(res) {
      this.$router.push(`/personal/myAssets/${res}?coinId=${this.$route.query.coinId}`);
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.bannerBox {
  background: @white;
  padding: 33px 30px 20px;
  .title {
    font-size: 36px;
    font-weight: 600;
    color: @text-color2;
  }
  .bottomBox {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 400;
    color: @intro-color;
    div {
      flex: 1;
      .number {
        margin-top: 10px;
        font-size: 30px;
        font-weight: 500;
        color: @title-color;
      }
    }
  }
}
.buttonBox {
  position: fixed;
  bottom: 21px;
  left: 30px;
  right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 138px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 2px rgba(212, 212, 239, 0.39);
  border-radius: 18px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 26px;
    font-weight: 400;
    color: #071c5e;
    img {
      width: 36px;
      margin-bottom: 15px;
    }
  }
}
</style>
